<template>
  <div class="index-container">
    <el-container>
      <el-header class="index-header">
        <div class="index-header-container">
          <div class="index-header-left">
            <div class="index-header-img">
              <img src="../assets/images/login/logo.png" class="index-pic" />
            </div>
            <div class="index-header-nav">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                active-text-color="#4F81F0"
                @select="handleSelect"
              >
                <el-menu-item
                  v-for="(item, index) in tabData"
                  :key="index"
                  :index="item.id.toString()"
                  >{{ item.title }}</el-menu-item
                >
              </el-menu>
            </div>
          </div>
          <div class="index-header-right">
            <!-- <el-dropdown trigger="click" placement="bottom"> -->
            <el-dropdown placement="bottom" trigger="hover">
              <span>
                {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="changePwd">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="outLogin"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="route-nav" v-show="activeIndex == 0 && routejump == true">
          <el-page-header
            @back="goBack"
            :content="initialTitle"
          ></el-page-header>
          <div
            class="route-title"
            v-for="(item, index) in routeTitle"
            :key="index"
          >
            <div class="route-title-line">/</div>
            {{ item }}
          </div>
        </div>
        <div class="index-main" v-show="routejump == false">
          <!-- 案件列表 -->
          <div
            class="index-main-container"
            v-show="activeIndex == 0 && routejump == false"
          >
            <div class="index-main-top">
              <div class="index-main-left">案件列表</div>
              <div class="index-main-right">
                <div class="index-main-import" @click="caseImport">
                  <el-button type="primary"
                    >案件导出
                    <!-- <a target="_blank" :href="`/web/web/preview/previewfile?download=1&filePath=${encodeURI(caseimportFile)}`"></a> -->
                  </el-button>
                </div>
                <div class="index-main-icon" @click="refreshData">
                  <i class="el-icon-refresh-left refresh-btn"></i>
                </div>
                <div class="index-main-btn">
                  <el-button type="primary" @click="batchRevoked()"
                    >批量撤回</el-button
                  >
                </div>
              </div>
            </div>
            <div class="index-main-body">
              <el-table
                ref="multipleTable"
                :data="casesList"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                :class="{ 'empty-table': !casesList.length > 0 }"
                v-loading="loading"
                element-loading-spinner="el-icon-loading"
                element-loading-text="正在加载案件列表数据"
              >
                <el-table-column
                  :selectable="checkSelectable"
                  fixed="left"
                  type="selection"
                  width="55"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  width="auto"
                  prop="batchNum"
                >
                  <template slot-scope="scope" slot="header">
                    <el-popover
                      :ref="`popover-${scope.$index}`"
                      trigger="click"
                      placement="bottom"
                    >
                      <div class="search-content">
                        <el-input
                          v-model="searchBatchNumber"
                          placeholder="请输入批次号"
                        />
                        <div class="search-btn">
                          <el-button
                            type="text"
                            @click="cencalBatchNumber(scope)"
                            >取消</el-button
                          >
                          <el-button
                            type="primary"
                            @click="confirmBatchNumber(scope)"
                            >确认</el-button
                          >
                        </div>
                      </div>
                      <div slot="reference" class="name-wrapper">
                        <div class="table-lable">批次号</div>
                        <div class="table-icon">
                          <i class="el-icon-search table-search"></i>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  width="auto"
                  prop="acceptNo"
                >
                  <!-- <template slot="header"> -->
                  <template slot-scope="scope" slot="header">
                    <el-popover
                      :ref="`popover-${scope.$index}`"
                      trigger="click"
                      placement="bottom"
                    >
                      <div class="search-content">
                        <el-input
                          v-model="search"
                          placeholder="请输入关键字模糊搜索"
                        />
                        <div class="search-btn">
                          <el-button type="text" @click="cencalSearch(scope)"
                            >取消</el-button
                          >
                          <el-button
                            type="primary"
                            @click="confirmSearch(scope)"
                            >确认</el-button
                          >
                        </div>
                      </div>
                      <div slot="reference" class="name-wrapper">
                        <div class="table-lable">受理号</div>
                        <div class="table-icon">
                          <i class="el-icon-search table-search"></i>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>

                <el-table-column
                  width="auto"
                  label="申请调解人"
                  prop="mdProposerName"
                >
                </el-table-column>
                <el-table-column width="auto" prop="mdRespondentName">
                  <template slot="header" slot-scope="scope">
                    <el-popover
                      :ref="`popover-${scope.$index}`"
                      trigger="click"
                      placement="bottom"
                    >
                      <div class="search-content">
                        <el-input
                          v-model="searchRespondentName"
                          placeholder="请输入"
                        />
                        <div class="search-btn">
                          <el-button
                            type="text"
                            @click="cencalRespondent(scope)"
                            >取消</el-button
                          >
                          <el-button
                            type="primary"
                            @click="confirmRespondent(scope)"
                            >确认</el-button
                          >
                        </div>
                      </div>
                      <div slot="reference" class="name-wrapper">
                        <div class="table-lable">被申请调解人</div>
                        <div class="table-icon">
                          <i class="el-icon-search table-search"></i>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  label="调解员"
                  width="auto"
                  prop="mediationUserName"
                >
                </el-table-column>
                <el-table-column width="auto" prop="statusDes">
                  <template slot="header" slot-scope="scope">
                    <el-popover
                      :ref="`popover-${scope.$index}`"
                      trigger="click"
                      placement="bottom"
                    >
                      <div class="search-content">
                        <el-select v-model="value3" placeholder="案件状态">
                          <el-option
                            v-for="(item, index) in options3"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <div class="search-btn">
                          <el-button type="text" @click="cencalStatus(scope)"
                            >取消</el-button
                          >
                          <el-button
                            type="primary"
                            @click="confirmStatus(scope)"
                            >确认</el-button
                          >
                        </div>
                      </div>
                      <div slot="reference" class="name-wrapper">
                        <div class="table-lable">状态</div>
                        <div class="table-icon">
                          <i class="el-icon-search table-search"></i>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="申请日期" width="auto" prop="applyTime">
                </el-table-column>
                <el-table-column
                  label="受理日期"
                  width="auto"
                  prop="acceptTime"
                >
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                  <template slot-scope="scope">
                    <el-button
                      @click="lookInfo(scope.row)"
                      type="text"
                      size="small"
                      >查看资料</el-button
                    >
                  </template>
                </el-table-column>
                <!-- 没有数据 -->
                <template slot="empty">
                  <div class="empty-img">
                    <img src="../assets/images/index/empty.png" />
                  </div>
                  <div class="empty-text">暂无相关数据</div>
                </template>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount"
                v-show="casesList.length > 0"
              >
              </el-pagination>
            </div>
          </div>
          <!-- 案件模板 -->
          <div
            class="index-main-container"
            v-show="activeIndex == 1 && routejump == false"
          >
            <div class="index-main-top">
              <div class="index-main-left">案件模板</div>
              <div class="index-main-right">
                <el-upload
                  class="upload-demo"
                  action="/web/proposerFileTemplate/import"
                  accept=".doc,.docx"
                  :multiple="false"
                  :show-file-list="false"
                  :file-list="templateList"
                  :on-success="templateSuccess"
                >
                  <div class="upload-tamplatebtn-main">
                    <el-button type="primary">上传模板</el-button>
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="index-main-body">
              <el-table
                ref="multipleTable"
                :data="casesTempalet"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChangeTwo"
                :class="{ 'empty-table': !casesTempalet.length > 0 }"
                v-loading="loadingTwo"
                element-loading-spinner="el-icon-loading"
                element-loading-text="正在加载案件模板数据"
              >
                <el-table-column fixed="left" type="selection" width="55">
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  width="auto"
                  prop="id"
                  label="编号"
                >
                </el-table-column>

                <el-table-column width="auto" label="模板名称" prop="name">
                </el-table-column>
                <el-table-column label="模板地址" width="auto" prop="path">
                </el-table-column>
                <el-table-column
                  label="创建日期"
                  width="auto"
                  prop="createTime"
                >
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                  <template slot-scope="scope">
                    <a
                      target="noDwonLoad"
                      :href="`/web/preview/previewfile?download=1&filePath=${scope.row.path}`"
                    >
                      <el-button type="text" size="small">下载</el-button>
                    </a>
                    <iframe style="display: none" name="noDwonLoad"></iframe>
                  </template>
                </el-table-column>
                <!-- 没有数据 -->
                <template slot="empty">
                  <div class="empty-img">
                    <img src="../assets/images/index/empty.png" />
                  </div>
                  <div class="empty-text">暂无相关数据</div>
                </template>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeTwo"
                @current-change="handleCurrentChangeTwo"
                :current-page="currentPageTwo"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesizeTwo"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCountTwo"
                v-show="casesTempalet.length > 0"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 上传案件 -->
          <div
            class="index-main-container"
            v-show="activeIndex == 2 && routejump == false"
          >
            <div class="index-main-top">
              <div class="index-main-left">上传案件</div>
              <div class="index-main-right">
                <div class="index-main-btn">
                  <el-button type="primary" @click="goupload"
                    >上传案件</el-button
                  >
                </div>
              </div>
            </div>
            <div class="index-main-body">
              <el-table
                ref="multipleTable"
                :data="uploadCase"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChangeThree"
                :class="{ 'empty-table': !uploadCase.length > 0 }"
                v-loading="loadingThree"
                element-loading-spinner="el-icon-loading"
                element-loading-text="正在加载案件数据"
              >
                <el-table-column fixed="left" type="selection" width="55">
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  width="auto"
                  prop="batchNum"
                  label="上传批次号"
                >
                </el-table-column>
                <el-table-column
                  width="auto"
                  label="导入总量"
                  prop="successSize"
                >
                </el-table-column>
                <el-table-column
                  label="成功数量"
                  width="auto"
                  prop="successSize"
                >
                </el-table-column>
                <el-table-column label="失败数量" width="auto" prop="errorSize">
                </el-table-column>
                <el-table-column
                  label="操作人"
                  width="auto"
                  prop="mdProposerName"
                >
                </el-table-column>
                <el-table-column
                  label="上传日期"
                  width="auto"
                  prop="createTime"
                >
                </el-table-column>
                <!-- 没有数据 -->
                <template slot="empty">
                  <div class="empty-img">
                    <img src="../assets/images/index/empty.png" />
                  </div>
                  <div class="empty-text">暂无相关数据</div>
                </template>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeThree"
                @current-change="handleCurrentChangeThree"
                :current-page="currentPageThree"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesizeThree"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCountThree"
                v-show="uploadCase.length > 0"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div
          class="route-content"
          v-show="activeIndex == 0 && routejump == true"
        >
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      title="本地导入"
      :visible.sync="uploadDailog"
      :show-close="false"
      top="25vh"
    >
      <div class="dailog-body">
        <div class="upload-content">
          <div class="dailog-lable">
            上传案件<span style="color: #ec0000">*</span>
          </div>
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept=".xlsx"
            :before-remove="beforeRemove"
            :on-change="handleChange"
            :http-request="uploadFile"
            multiple
            :file-list="fileList"
            :auto-upload="false"
          >
            <div class="upload-btn-main">
              <el-button size="small" type="primary">
                <i class="el-icon-upload2 upload-icon"></i>选择参数表【XLSX】
              </el-button>
            </div>
          </el-upload>
        </div>
        <div class="generate-application">
          <div class="dailog-lable">生成申请书</div>
          <el-select
            @change="selectTemplate"
            v-model="value"
            value-key="id"
            placeholder="请选择申请书模板"
          >
            <el-option
              v-for="item in casesTempalet"
              :key="item.name"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="uploadDailog = false">关闭</el-button>
        <el-button type="primary" @click="importUploadFile">导入</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改密码"
      :visible.sync="changePassDailog"
      :show-close="false"
      top="25vh"
    >
      <div class="dailog-body">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
          <el-form-item label="原密码" prop="plainPass">
            <el-input
              type="text"
              v-model="ruleForm.plainPass"
              placeholder="请输入原密码"
              show-password
              @input="checkPlainPass"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPass">
            <el-input
              type="text"
              v-model="ruleForm.newPass"
              @input="checkNewPass"
              show-password
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPass">
            <el-input
              type="text"
              v-model="ruleForm.confirmPass"
              @input="checkConfirmPass"
              show-password
              placeholder="请再次输入确认密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="changePassDailog = false"
          >取消</el-button
        >
        <el-button type="primary" @click="changeConfirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Index",
  data() {
    let validPlainPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("原密码不能为空"));
      } else if (value != this.checkPwd) {
        callback(new Error("原密码错误，请重新输入"));
      }  else {
        callback();
      }
    };
    let validNewPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("新密码不能为空"));
      } else if (value == this.ruleForm.plainPass) {
        callback(new Error("新密码不能与原密码一致"));
      } else {
        callback();
      }
    };
    let validConfirmPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("确认密码不能为空"));
      } else if (value != this.ruleForm.newPass) {
        callback(new Error("请与新密码保持一致"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        plainPass: "", // 原密码
        newPass: "", // 新密码
        confirmPass: "", // 确认密码
      },
      rules: {
        plainPass: [
          { validator: validPlainPass, trigger: "blur", required: true },
        ],
        newPass: [{ validator: validNewPass, trigger: "blur", required: true }],
        confirmPass: [
          { validator: validConfirmPass, trigger: "blur", required: true },
        ],
      },

      activeIndex: "0",
      currentPage: 1,
      pagesize: 10, //每页的数据条数
      totalCount: 0,
      currentPageTwo: 1,
      pagesizeTwo: 10, //每页的数据条数
      totalCountTwo: 0,
      currentPageThree: 1,
      pagesizeThree: 10, //每页的数据条数
      totalCountThree: 0,
      uploadDailog: false, // 上传案件dailog
      search: "", // 列表关键字模糊搜索
      searchRespondentName: "", // 被申请调解人搜索
      searchBatchNumber: "", // 批次号搜索
      tabheaderLight: false, // 表头带搜索 操作完成点击确认单元格背景色变深色

      username: "", // 所登陆用户名
      // visibleOne: false,
      // visibleTwo: false,
      // visiblethree: false,

      initialTitle: "案件列表",
      routeTitle: ["案件详情"], // 路由标题

      fileList: [],
      templateList: [],

      options: [],
      value: [],
      templateId: "", // 生成申请书模板id

      options2: [],
      value2: [],
      list: [],
      loadingMdRespondentName: false,
      states: [],

      options3: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "待受理",
        },
        {
          value: "1",
          label: "不予受理",
        },
        {
          value: "2",
          label: "待答辩",
        },
        {
          value: "3",
          label: "已答辩",
        },
        {
          value: "4",
          label: "司法确认",
        },
        {
          value: "5",
          label: "已归档",
        },
        {
          value: "-1",
          label: "已撤案",
        },
      ],
      value3: [],

      tabData: [
        {
          id: "0",
          title: "案件列表",
        },
        {
          id: "1",
          title: "案件模板",
        },
        {
          id: "2",
          title: "上传案件",
        },
      ],
      // 案件列表
      casesList: [
        // {
        //   acceptanceNumber: "景德镇仲网调字【2022】第1号", // 受理号
        //   applicationpeople: "测试申请人", // 申请调解人
        //   respondent: "测试001", // 被申请调解人
        //   mediator: "丰越校", // 调解员
        //   status: "待答辩", // 状态
        //   applicationdate: "2022-01-01 09:00:00", // 申请日期
        //   acceptancedate: "2022-01-01 10:00:00", // 受理日期
        // },
      ],
      // 案件模板
      casesTempalet: [],

      // 上传案件
      uploadCase: [],

      multipleSelection: [],
      multipleSelectionTwo: [],
      multipleSelectionThree: [],
      loading: false,
      loadingTwo: false,
      loadingThree: false,
      routejump: false,

      // 批量撤回
      caseIds: [],

      // 案件导出
      caseimportFile: "",

      changePassDailog: false, // 修改密码dailog
      checkPwd: '', // 用作修改密码时校验原密码
    };
  },

  mounted() {
    this.getCasesList();
    // console.log(this.$route.path,this.activeIndex);
    if (
      this.$route.path != "/" &&
      this.$route.path == "/lookcasedetail" &&
      this.activeIndex == 0
    ) {
      this.routejump = true;
    } else {
      this.routejump = false;
    }
  },
  watch: {
    $route: function (newUrl, oldUrl) {
      // console.log(newUrl, oldUrl);
      if (newUrl.path == "/") {
        this.routejump = false;
      }

      if (newUrl.path != "/") {
        this.visibleOne = false;
        this.visibleTwo = false;
        this.visiblethree = false;
      }
      if (newUrl.path == "/lookcasedetail" && this.activeIndex == 0) {
        this.routejump = true;
      } else {
        this.routejump = false;
      }
    },
  },
  created() {
    this.username = sessionStorage.getItem("username");
    this.checkPwd = sessionStorage.getItem("password");
    // this.getuserN();
  },
  methods: {
    changePwd(){
      this.changePassDailog = true
    },
    // 确认修改密码
    changeConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let password = this.ruleForm.confirmPass;
          this.$axios
            .post(
              `/web//web/proposer/changePassword?password=${password}`
            )
            .then((res) => {
              if (res && res.data.code == 0 && res.status == 200) {
                this.$message({
                  message: "修改成功，请重新登陆！",
                  type: "success",
                });
                this.$router.push("/");
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "请求异常，服务器出错",
              });
            });
        } else {
          //不通过
          return false;
        }
      });
    },
    checkPlainPass(e) {
      // this.$options.methods.fn.bind(this)();
      this.ruleForm.plainPass = e;
    },
    // 新密码
    checkNewPass(e) {
      // this.$options.methods.fn.bind(this)();
      this.ruleForm.newPass = e;
    },
    // 确认新密码
    checkConfirmPass(e) {
      // this.$options.methods.fn.bind(this)();
      this.ruleForm.confirmPass = e;
    },
    // 刷新案件列表数据
    refreshData() {
      // this.visibleOne = false;
      // this.visibleTwo = false;
      // this.visiblethree = false;
      this.loading = true;
      setTimeout((res) => {
        this.loading = false;
        this.getCasesList();
      }, 500);
    },
    // 案件列表查看资料
    lookInfo(row) {
      // console.log(row);
      this.routejump = true;
      this.$router.push({ name: "LookCaseDetail", query: { id: row.id } });
    },
    goBack() {
      // console.log('go back');
      this.$router.go(-1);
    },
    //案件列表——>案件详情
    golookcasedetail() {
      this.routejump = true;
      this.$router.push("lookcasedetail");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handlePreview(file) {
      console.log(file);
    },
    // handleExceed(files, fileList) {
    //   // this.fileList = []
    //   // this.fileList.push(files[0])   // 默认覆盖上个文件
    //   this.$message.warning(
    //     `每次最多只能选择 1 个文件，本次选择将覆盖上次选择的文件`
    //   );
    // },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    uploadFile(params) {
      // 手动上传导入文件
      // console.log(params);
      let file = params.file;
      let formData = new FormData();
      formData.append("excelFile", file);
      formData.append("pFileTemplateId", this.templateId);

      this.$axios
        .post(`/web/web/caseImport/import`, formData)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.$message({
              message: "导入成功",
              type: "success",
            });
            this.loadingThree = true;
            setTimeout((res) => {
              this.loadingThree = false;
              this.getUploadList();
            }, 300);
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    // 点击按钮上传案件
    importUploadFile() {
      this.uploadDailog = false;
      this.$refs.upload.submit();
    },

    // 取消列表模糊搜索
    cencalBatchNumber(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
    },
    cencalSearch(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
    },
    cencalRespondent(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
    },
    cencalStatus(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
    },
    // 确认列表模糊搜索
    confirmBatchNumber(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.loading = true;
      setTimeout((res) => {
        this.loading = false;
        this.$axios
          .get(
            `/web/web/proposer/caseList?page=${this.currentPage}&rows=${this.pagesize}&batchNum=${this.searchBatchNumber}&acceptNo=${this.search}&mdRespondentName=${this.searchRespondentName}&status=${this.value3}`
          )
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.totalCount = res.data.count;
              this.casesList = res.data.data;
              this.states = [];
              this.casesList.map((item, index) => {
                this.states.push(item.mdRespondentName);
              });
            } else if (res.data.code == 2) {
              this.$message({
                message: res.data.msg,
              });
              setTimeout((res) => {
                this.$router.push("/");
              }, 2000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: "请求异常，服务器出错",
            });
          });
      }, 300);
      //
    },
    confirmSearch(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.loading = true;
      setTimeout((res) => {
        this.loading = false;
        this.$axios
          .get(
            `/web/web/proposer/caseList?page=${this.currentPage}&rows=${this.pagesize}&batchNum=${this.searchBatchNumber}&acceptNo=${this.search}&mdRespondentName=${this.searchRespondentName}&status=${this.value3}`
          )
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.totalCount = res.data.count;
              this.casesList = res.data.data;
              this.states = [];
              this.casesList.map((item, index) => {
                this.states.push(item.mdRespondentName);
              });
            } else if (res.data.code == 2) {
              this.$message({
                message: res.data.msg,
              });
              setTimeout((res) => {
                this.$router.push("/");
              }, 2000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: "请求异常，服务器出错",
            });
          });
      }, 300);
      //
    },
    confirmRespondent(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      // this.loading = true;
      setTimeout((res) => {
        // this.loading = false;
        this.$axios
          .get(
            `/web/web/proposer/caseList?page=${this.currentPage}&rows=${this.pagesize}&batchNum=${this.searchBatchNumber}&acceptNo=${this.search}&mdRespondentName=${this.searchRespondentName}&status=${this.value3}`
          )
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.totalCount = res.data.count;
              this.casesList = res.data.data;
              this.states = [];
              this.casesList.map((item, index) => {
                this.states.push(item.mdRespondentName);
              });
            } else if (res.data.code == 2) {
              this.$message({
                message: res.data.msg,
              });
              setTimeout((res) => {
                this.$router.push("/");
              }, 2000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: "请求异常，服务器出错",
            });
          });
      }, 300);
    },
    confirmStatus(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.loading = true;
      setTimeout((res) => {
        this.loading = false;
        this.$axios
          .get(
            `/web/web/proposer/caseList?page=${this.currentPage}&rows=${this.pagesize}&batchNum=${this.searchBatchNumber}&acceptNo=${this.search}&mdRespondentName=${this.searchRespondentName}&status=${this.value3}`
          )
          .then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.totalCount = res.data.count;
              this.casesList = res.data.data;
              this.states = [];
              this.casesList.map((item, index) => {
                this.states.push(item.mdRespondentName);
              });
            } else if (res.data.code == 2) {
              this.$message({
                message: res.data.msg,
              });
              setTimeout((res) => {
                this.$router.push("/");
              }, 2000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: "请求异常，服务器出错",
            });
          });
      }, 300);
    },
    // 退出登陆
    outLogin() {
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("password");
      this.$message({
        message: "您已退出登陆，请重新登陆！",
        type: "success",
      });
      this.$router.replace("/");
    },
    // 打开上传案件弹窗
    goupload() {
      this.uploadDailog = true;
    },

    // 生成申请书选中的值
    selectTemplate(e) {
      this.templateId = e.id;
    },

    // 案件列表
    getCasesList() {
      this.$axios
        .get(
          `/web/web/proposer/caseList?page=${this.currentPage}&rows=${this.pagesize}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.totalCount = res.data.count;
            this.casesList = res.data.data;

            this.states = [];
            this.casesList.map((item, index) => {
              this.states.push(item.mdRespondentName);
            });

            this.list = this.states.map((item) => {
              return { value: `${item}`, label: `${item}` };
            });
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    // 加载案件模板列表
    getTemplateList() {
      this.$axios
        .get(
          `/web/web/proposerFileTemplate/list?page=${this.currentPageTwo}&rows=${this.pagesizeTwo}`
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCountTwo = res.data.count;
            this.casesTempalet = res.data.data;
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    // 上传模板成功
    templateSuccess(response) {
      if (response.code == 0) {
        this.$message({
          message: "模板上传成功",
          type: "success",
        });
        this.loadingTwo = true;
        setTimeout((res) => {
          this.loadingTwo = true;
          this.getTemplateList();
        }, 300);
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 上传案件列表
    getUploadList() {
      this.$axios
        .get(
          `/web/web/caseImport/list?page=${this.currentPageThree}&rows=${this.pagesizeThree}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.totalCountThree = res.data.count;
            this.uploadCase = res.data.data;
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },

    // 撤案禁选
    checkSelectable(row) {
      if (row.statusDes == "已撤案") {
        return false;
      } else {
        return true;
      }
    },

    //案件导出
    caseImport() {
      this.$axios
        .post(
          `/web/web/proposer/exportMdCase?acceptNo=${this.search}&batchNum=${this.searchBatchNumber}&mdRespondentName=${this.searchRespondentName}&status=${this.value3}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            // this.caseimportFile = res.data.msg;
            window.location.href = `/web/web/preview/previewfile?download=1&filePath=${encodeURI(
              res.data.msg
            )}`;
            // window.open(`/web/web/preview/previewfile?download=1&filePath=${encodeURI(res.data.msg)}`)
            // this.$axios
            //   .post(
            //     `/web/web/preview/previewfile?download=1&filePath=${encodeURI(res.data.msg)}`
            //   )
            //   .then((res) => {
            //     // console.log(res);
            //     if (res.data.code == 0) {
            //       this.$message({
            //         message: "导出成功",
            //         type: "success",
            //       });
            //       // window.location.href = '/web/web/proposer/downloadExcel?filepath='+res.data.msg;
            //     } else {
            //       this.$message({
            //         message: res.data.msg,
            //         type: "error",
            //       });
            //     }
            //   })
            //   .catch((err) => {
            //     this.$message({
            //       message: "请求异常，服务器出错",
            //     });
            //   });
            // window.location.href = '/web/web/proposer/downloadExcel?filepath='+res.data.msg;
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },

    // 批量撤回
    batchRevoked() {
      this.multipleSelection.map((item, index) => {
        this.caseIds.push(item.id);
      });

      let formData = new FormData();
      formData.append("caseIds", this.caseIds);

      this.$axios
        .post(`/web/web/case/batchRevoked`, formData)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.$message({
              message: "撤案成功",
              type: "success",
            });
            this.loading = true;
            setTimeout((res) => {
              this.loading = false;
              this.getCasesList();
            }, 300);
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },

    handleSelect(key, keyPath) {
      this.activeIndex = key;
      if (key != 0) {
        this.visibleOne = false;
        this.visibleTwo = false;
        this.visiblethree = false;
      }
      if (key == 0) {
        this.loading = true;
        setTimeout((res) => {
          this.loading = false;
          this.getCasesList();
        }, 500);
      }
      if (key == 0 && this.$route.path == "/lookcasedetail") {
        this.routejump = true;
        this.getCasesList();
      }
      if (key == 1) {
        this.routejump = false;
        this.loadingTwo = true;
        setTimeout((res) => {
          this.loadingTwo = false;
          this.getTemplateList();
        }, 500);
      }
      if (key == 2) {
        this.routejump = false;
        this.loadingThree = true;
        setTimeout((res) => {
          this.loadingThree = false;
          this.getUploadList();
        }, 500);
        this.getTemplateList();
      }
    },
    // 案件列表
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getCasesList();
      // this.currentPage = 1
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getCasesList();
    },

    // 案件模板
    // 表格多选
    handleSelectionChangeTwo(val) {
      this.multipleSelectionTwo = val;
    },
    // 表格分页
    handleSizeChangeTwo(val) {
      // console.log(`每页 ${val} 条`);
      this.pagesizeTwo = val;
      this.getTemplateList();
    },
    handleCurrentChangeTwo(val) {
      // console.log(`当前页: ${val}`);
      this.currentPageTwo = val;
      this.getTemplateList();
    },

    // 上传案件
    // 表格多选
    handleSelectionChangeThree(val) {
      this.multipleSelectionThree = val;
    },
    // 表格分页
    handleSizeChangeThree(val) {
      // console.log(`每页 ${val} 条`);
      this.pagesizeThree = val;
      this.getUploadList();
    },
    handleCurrentChangeThree(val) {
      // console.log(`当前页: ${val}`);
      this.currentPageThree = val;
      this.getUploadList();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/index.scss";
</style>